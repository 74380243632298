<template>
  <div>
    <span
      v-if="item.inspecStatus == config.STATUS_INSPEC_AGUARDANDO"
      class="badge badge-warning-yellow"
      >Pendente de vistoria</span
    >
    <span
      v-if="
        item.inspecStatus == config.STATUS_INSPEC_AVALIANDO ||
          item.inspecStatus == config.STATUS_INSPEC_DADOS_INCOMPLETOS
      "
      class="badge badge-warning"
      >Em análise</span
    >
    <span
      v-if="
        (item.inspecStatus == config.STATUS_INSPEC_APROVADO ||
          item.inspecStatus == config.STATUS_INSPEC_VALOR_ALTERADO) &&
          !item.urlApolice
      "
      class="badge badge-primary"
      >Em emissão</span
    >
    <span
      v-if="item.inspecStatus == config.STATUS_INSPEC_RECUSADO"
      class="badge badge-danger"
      >Recusado</span
    >
    <div
      v-if="
        (item.inspecStatus == config.STATUS_INSPEC_APROVADO ||
          item.inspecStatus == config.STATUS_INSPEC_VALOR_ALTERADO) &&
          item.urlApolice
      "
    >
      <span class="badge badge-success">Aprovado</span>
      <br />
    </div>
  </div>
</template>

<script>
import config from "../config";
export default {
  props: ["item"],
  components: {},
  data() {
    return { config };
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.badge-warning-yellow {
  color: #212529;
  background-color: yellow;
}
</style>
