<template>
  <div class="container pb-5">
    <div class="pd-5">
      <div class=" pt-5">
        <p class="mt-3 text-left">
          Utilize os campos abaixo para filtrar os dados dos seus clientes
        </p>
      </div>
    </div>
    <hr class="mt-1" />

    <div class="row mb-4">
      <div class="col-md-4 mb-2 ">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              Telefone
            </span>
          </div>
          <input type="text" class="form-control" v-model="telefone" />
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              Email
            </span>
          </div>
          <input type="text" class="form-control" v-model="email" />
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              CPF
            </span>
          </div>
          <input type="text" class="form-control" v-model="cpf" />
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-4 mb-2 ">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Mês</span>
          </div>

          <b-form-select v-model="mesPesquisa">
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(item, index) in mesSeguro"
              :key="'mesPesquisa' + index"
              :value="item.codigo"
            >
              {{ item.nome }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Ano</span>
          </div>

          <b-form-select v-model="anoPesquisa">
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(item, index) in anoBikes"
              :key="'anoPesquisa' + index"
              :value="item.anoBike"
            >
              {{ item.anoBike }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Status</span>
          </div>

          <b-form-select v-model="status">
            <option :value="undefined">Selecione...</option>
            <option
              v-for="(item, index) in statusVistoria"
              :key="'status' + index"
              :value="item.id"
            >
              {{ item.nome }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-4 mb-2">
        <b-button variant="success" @click.prevent="limparFiltro()"
          >Limpar Filtro</b-button
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="text-center" v-if="filtrados.length == 0 && !loading">
          <p>Ainda não há seguros para essa pesquisa.</p>
        </div>
        <div v-if="!filtrados.length == 0 && !loading">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Nome do cliente</th>
                <th scope="col">CPF</th>
                <th scope="col">Telefone</th>
                <th scope="col">Email</th>
                <th scope="col">Valor</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filtrados" :key="item.id">
                <td>
                  {{ formatacaoData.formatarDataLocal(item.dataCriado) }}
                </td>
                <td>{{ item.nomeCliente }}</td>
                <td>{{ helper.formatacaoCpf(item.cpfCliente) }}</td>
                <td>{{ formatacaoTelefone(item.telefoneCliente) }}</td>
                <td>{{ item.emailCliente }}</td>
                <td>{{ formatacaoMoeda3(item.valorPlanoSeguro) }}</td>
                <td><Status :item="item" /></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="loading" class="text-center">
          <lottie-player
            src="/anim/mountain_bike.json"
            background="transparent"
            speed="1.7"
            style="width: 72px; height: 72px; margin:0 auto;"
            autoplay
            loop
            class="mt-3"
          ></lottie-player>
          <div>Carregando, por favor aguarde...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Status from "../../components/Status";
import moment from "moment";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import formatacaoData from "../../helpers/formatacaoData";
import helper from "../../helpers/helper";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";
import anoBikes from "../../data/anoBikesSeguro";
import mesSeguro from "../../helpers/mesSeguro";

export default {
  components: {
    Status,
  },
  data() {
    return {
      cpf: undefined,
      status: undefined,
      config,
      anoBikes,
      mesSeguro,
      loading: false,
      moment: moment,
      seguros: [],
      filtrados: [],
      telefone: null,
      email: null,
      idUsuarioAdm: undefined,
      formatacaoMoeda3,
      formatacaoData,
      formatacaoTelefone,
      helper,
      seguro: undefined,
      cnpjLoja: undefined,
      anoPesquisa: undefined,
      mesPesquisa: undefined,
      statusVistoria: [
        { id: 0, nome: "Pendente de vistoria" },
        { id: 1, nome: "Em análise" },
        { id: 2, nome: "Em emissão" },
        { id: 3, nome: "Recusado" },
        { id: 4, nome: "Aprovado" },
      ],
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (!userInfo.cnpj) this.$router.replace("/parceiro");

    this.cnpjLoja = userInfo.cnpj;
  },
  mounted() {
    this.buscarSegurados();
  },
  methods: {
    limparFiltro() {
      this.telefone = null;
      this.email = null;
      this.cpf = null;
      this.status = null;
      this.anoPesquisa = undefined;
      this.mesPesquisa = undefined;
      this.filtrados = this.seguros;
      return;
    },
    filtrarPorEmail() {
      if (!this.email) this.filtrados = this.seguros;

      this.filtrados = this.seguros.filter(
        (item) => item.emailCliente.search(this.email) == 0
      );
    },
    filtrarPorCPF() {
      if (!this.cpf) this.filtrados = this.seguros;

      this.filtrados = this.seguros.filter(
        (item) => item.cpfCliente.search(this.cpf) == 0
      );
    },
    filtrarPorStatus() {
      if (!this.status) this.filtrados = this.seguros;

      if (this.status == 0) {
        return (this.filtrados = this.seguros.filter(
          (item) => item.inspecStatus == config.STATUS_INSPEC_AGUARDANDO
        ));
      }

      if (this.status == 1) {
        return (this.filtrados = this.seguros.filter(
          (item) =>
            item.inspecStatus == config.STATUS_INSPEC_AVALIANDO ||
            item.inspecStatus == config.STATUS_INSPEC_DADOS_INCOMPLETOS
        ));
      }

      if (this.status == 2) {
        return (this.filtrados = this.seguros.filter(
          (item) =>
            (item.inspecStatus == config.STATUS_INSPEC_APROVADO ||
              item.inspecStatus == config.STATUS_INSPEC_VALOR_ALTERADO) &&
            !item.urlApolice
        ));
      }

      if (this.status == 3) {
        return (this.filtrados = this.seguros.filter(
          (item) => item.inspecStatus == config.STATUS_INSPEC_RECUSADO
        ));
      }

      if (this.status == 4) {
        return (this.filtrados = this.seguros.filter(
          (item) =>
            (item.inspecStatus == config.STATUS_INSPEC_APROVADO ||
              item.inspecStatus == config.STATUS_INSPEC_VALOR_ALTERADO) &&
            item.urlApolice
        ));
      }
    },
    filtrarPorTelefone() {
      if (!this.telefone) this.filtrados = this.seguros;

      this.filtrados = this.seguros.filter(
        (item) => item.telefoneCliente.search(this.telefone) == 0
      );
    },
    async buscarSegurados() {
      this.loading = true;

      var data = {
        cnpj: this.cnpjLoja,
        ano: this.anoPesquisa,
        mes: this.mesPesquisa,
      };

      try {
        var response = await axios.post(
          `${config.API_URLV2}/seguro/buscarSegurosPorDataParceiro`,
          data
        );
        this.seguros = response.data;
        this.filtrados = response.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          text:
            "Não foi possível consultar os segurados. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
  watch: {
    email: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.filtrarPorEmail();
      }
    },
    telefone: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.filtrarPorTelefone();
      }
    },
    anoPesquisa: function(newValue, oldValue) {
      if (newValue != oldValue && this.mesPesquisa != undefined) {
        this.buscarSegurados();
      }
    },
    mesPesquisa: function(newValue, oldValue) {
      if (newValue != oldValue && this.anoPesquisa != undefined) {
        this.buscarSegurados();
      }
    },
    cpf: function(newValue, oldValue) {
      if (newValue != oldValue && this.cpf != undefined) {
        this.filtrarPorCPF();
      }
    },
    status: function(newValue, oldValue) {
      if (newValue != oldValue && this.status != undefined) {
        this.filtrarPorStatus();
      }
    },
  },
};
</script>
<style scoped>
.minha-conta {
  height: 100%;
  /* background-color: #f7f7f7; */
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
