var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.inspecStatus == _vm.config.STATUS_INSPEC_AGUARDANDO)?_c('span',{staticClass:"badge badge-warning-yellow"},[_vm._v("Pendente de vistoria")]):_vm._e(),(
      _vm.item.inspecStatus == _vm.config.STATUS_INSPEC_AVALIANDO ||
        _vm.item.inspecStatus == _vm.config.STATUS_INSPEC_DADOS_INCOMPLETOS
    )?_c('span',{staticClass:"badge badge-warning"},[_vm._v("Em análise")]):_vm._e(),(
      (_vm.item.inspecStatus == _vm.config.STATUS_INSPEC_APROVADO ||
        _vm.item.inspecStatus == _vm.config.STATUS_INSPEC_VALOR_ALTERADO) &&
        !_vm.item.urlApolice
    )?_c('span',{staticClass:"badge badge-primary"},[_vm._v("Em emissão")]):_vm._e(),(_vm.item.inspecStatus == _vm.config.STATUS_INSPEC_RECUSADO)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Recusado")]):_vm._e(),(
      (_vm.item.inspecStatus == _vm.config.STATUS_INSPEC_APROVADO ||
        _vm.item.inspecStatus == _vm.config.STATUS_INSPEC_VALOR_ALTERADO) &&
        _vm.item.urlApolice
    )?_c('div',[_c('span',{staticClass:"badge badge-success"},[_vm._v("Aprovado")]),_c('br')]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }