export default [
    {
        nome:"Janeiro",
        codigo:"01"
    },
    {
        nome:"Fevereiro",
        codigo:"02"
    },
    {
        nome:"Março",
        codigo:"03"
    },
    {
        nome:"Abril",
        codigo:"04"
    },
    {
        nome:"Maio",
        codigo:"05"
    },
    {
        nome:"Junho",
        codigo:"06"
    },
    {
        nome:"Julho",
        codigo:"07"
    },
    {
        nome:"Agosto",
        codigo:"08"
    },
    {
        nome:"Setembro",
        codigo:"09"
    },
    {
        nome:"Outubro",
        codigo:"10"
    },
    {
        nome:"Novembro",
        codigo:"11"
    },
    {
        nome:"Dezembro",
        codigo:"12"
    },
]